<template>
    <div>

    </div>
</template>

<script>
    import {getUrlParam} from '@/assets/js/utils';
    export default {
        name: 'Index',
        data () {
            return {}
        },
        created () {
            window.APPID = 'wx8084a961292f4caf'
            this.getCode()
        },
        methods: {
            getCode () { // 非静默授权，第一次有弹框
                const code = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
                const local = window.location.href;
                console.log('code', code);
                console.log('local', local);
                if (code == null || code === '') {
                    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + window.APPID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
                } else {
                    this.getOpenId(code) //把code传给后台获取用户信息
                }
            },
            getOpenId (code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
                let _this = this
                this.$http.post('/api/user/wechat/login', {code: code}).then((res) => {
                    let datas = res.data
                    if (datas.code === 0 ) {
                        console.log('成功')
                    }
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
